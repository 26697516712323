import apiClient from './api'

export const getOrderDetail = async(params) => {
  const url = `/api/getOrderDetail`
  return apiClient.get(url, { params })
}

export const createPayment = async (data) => {
  const url = `/api/createPayment`
  return apiClient.post(url, data)
}

export const userCancelOrder = async (data) => {
  const url = `/api/userCancelOrder`
  return apiClient.post(url, data)
}