import "./NavBar.css";
import logoImg from 'assets/images/robot-logo.png'

const NavBar = () => {

  return (
    <div className="sub-container nav-main">
      <div className="nav-container">
        <img src={logoImg} alt="" width={100} />
      </div>
      <div className="nav-mobile">
        <div className="mobile-expanded-menu">
        </div>
      </div>
    </div>
  );
};

export default NavBar;
