export const invoiceTypes = {
  carrier: 'carrier',
  donate: 'donate',
  // bound: 'bound'
}

export const confirmMsgs = {
  confirm: {
    value: 'confirm',
    msg: {
      ch: '付款已完成, 請關閉頁面.',
      en: 'Payment completed, please close the page.'
    }
  },
  cancel: {
    value: 'cancel',
    msg: {
      ch: '付款已取消, 請關閉頁面.',
      en: 'Payment canceled, please close the page.'
    }
  },
  failed: {
    value: 'failed',
    msg: {
      ch: '付款失敗, 請關閉頁面.',
      en: 'Payment failed, please close the page.'
    }
  },
  notFound: {
    value: 'notFound',
    msg: {
      ch: '訂單已失效, 請關閉頁面.',
      en: 'Not found, please close the page.'
    }
  }
}


export const modalMsg = {
  cancel: {
    value: 'cancel',
    ch: '確定取消訂單?',
    en: 'Are you sure you want to cancel your order?'
  },
  payment: {
    value: 'payment',
    ch: '送出訂單?',
    en: 'Are you sure you want to submit your order?'
  }
}