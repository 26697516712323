

const ErrorView = () => {
  return (
    <section className="products-section">
      <div style={{ height: "70vh" }} className="sub-container">
        <h1>404</h1>
        <h4>Oops Page Not Found!</h4>
      </div>
    </section>
  );
};
export default ErrorView;
