import axios from 'axios'
import errorHandle from './errorHandle'

const client = axios.create({
  // baseURL: 'http://localhost:3300',
  headers: { 'Content-Type': 'application/json' },
  timeout: 40 * 1000,
  responseType: 'json',
  withCredentials: true
})

client.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

client.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  response => {
    // console.log('response')
    return response.data
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  err => {
    const { response } = err
    // console.log('response err', err)
    if (response) {
      // receive the response, but has error
      errorHandle(response.status, err)
      return Promise.reject(response)
    }

    // can not receive response
    // 請求過時或者是斷網
    if (!window.navigator.onLine) {
      // popupAlert('There are some problems on network. Please reload again')
    } else {
      // maybe Program have some problem
      return Promise.reject(response)
    }
  }
)

export default client
