import Order from "../components/Cart/Order";

const OrderInfo = () => {
  return (
    <div>
      <main>
        <Order></Order>
      </main>
    </div>
  );
};
export default OrderInfo;
