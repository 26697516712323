import { useState, useEffect } from "react";
import { confirmMsgs } from "helpers/const";

const OrderConfirm = ({ type }) => {
  const [msg, setMsg] = useState(confirmMsgs.confirm.msg)

  useEffect(() => {
    setMsg(confirmMsgs[type].msg)
  }, [type])

  return (
    <section className="products-section">
      <div style={{ height: "70vh" }} className="sub-container">
        <h1>{ msg.ch } <br/><br/> { msg.en }</h1>
      </div>
    </section>

  );
};

export default OrderConfirm;
