import "./ShopFooter.css";

const ShopFooter = () => {
  const newYear = new Date().getFullYear();
  return (
    <div className="sub-container">
      <div className="bottom-section">
        <div className="bottom-section-left">
        </div>
        <div className="bottom-sectino-right">&copy; {newYear} 寶晟科技股份有限公司</div>
      </div>
    </div>
  );
};
export default ShopFooter;
