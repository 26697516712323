import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash'
import { getOrderDetail, createPayment, userCancelOrder } from 'routes/orderService'

import OrderSummary from "./OrderSummary/OrderSummary";
import "./Order.css";
import { invoiceTypes } from "helpers/const";

const Order = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(0)
  const [invoiceInfo, setInvoiceInfo] = useState({ carrierType: invoiceTypes.carrier, carrierCode: '' });
  const [orderInfo, setOrderInfo] = useState({});

  const initOrder = async () => {
    try {
      const res = await getOrderDetail({ id });
      console.log('orderInfo res', res);
      if (res.status === 'done') {
        navigate('/orderConfirm');
      } else if (res.status.indexOf('cancel') !== -1 ) {
        navigate('/orderCancel');
      } else if (res.status == 'expired' ) {
        navigate('/orderFailed');
      }else {
        setOrderInfo(res);
      }
    } catch (error) {
      console.error('[OrderSummary]: ', error)
      navigate('/orderNotFound');
    }
  }

  const orderCancel = async () => {
    try {
      await userCancelOrder({ uuid: orderInfo.uuid });
      navigate('/orderCancel');
    } catch (error) {
      console.error('[orderCancel]: ', error)
    }
  }

  const checkOut = async () => {
    try {
      console.log('checkOut', invoiceInfo);
      setStep(step + 1);
      const payload = {
        ...invoiceInfo,
        id,
      }
      const res = await createPayment(payload);
      if (isMobile) {
        //window.location.replace(res.info.paymentUrl.app);
        window.location.replace(res.info.paymentUrl.web);  //dev
      } else {
        window.location.replace(res.info.paymentUrl.web);
      }
    } catch (error) {
      console.debug('checkOut res: ', error);
    }
  };

  useEffect(() => {
    if (isEmpty(id)) return;
    initOrder();
  }, [id])

  return (
    <>
      <div className="main-order-container">
        <div className="order-summary">
          <h2 className='summary-title'>
            訂單明細 / Order detail
          </h2>
          {step === 0 &&
            <OrderSummary {...{
              invoiceInfo, setInvoiceInfo, orderInfo, orderCancel, checkOut
            }} />
          }
          {step !== 0 &&
            <div style={{ height: "70vh" }} className="sub-container">
              <h3 className='summary-title'>
                付款中... / Processing...
              </h3>
            </div>
          }
        </div>
      </div>
    </>
  );
};
export default Order;
