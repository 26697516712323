import React from "react";

import "./Modal.css";
// import { useGlobalContext } from "@/components/GlobalContext/GlobalContext";
import ClipLoader from "react-spinners/ClipLoader";
import { useState } from "react";
import { modalMsg } from "helpers/const";

const Modal = ({ modalStatus, clickCancel, clickSubmit }) => {

  const [loading, setLoading] = useState(false);

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-cancel">
        </div>
        <div className="modal-header">
          <h3>{ modalMsg?.[modalStatus.type]?.ch  } <br/> { modalMsg?.[modalStatus.type]?.en  }</h3>
        </div>
        <div className="modal-body">
          <div className="form-group cancel-modal-group">
            <button
              type="submit"
              className="btn-rounded btn-submit btn-submit-small btn-cancel"
              onClick={() =>  clickCancel() }
            >
              取消 / Cancel
              <span>
                <ClipLoader
                  loading={loading}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </span>
            </button>
            <button
              type="button"
              className="btn-rounded btn-submit btn-submit-small"
              onClick={() => {
                setLoading(true);
                clickSubmit(modalStatus.type);
              }}
            >
              確定 / Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
