import { createContext, useContext } from "react";
// import useModal from "../../store/modal";
const globalContext = createContext();

export const useGlobalContext = () => useContext(globalContext);

const GlobalContext = ({ children }) => {
  // const modal = useModal();
  return (
    <globalContext.Provider value={{}}>
      {children}
    </globalContext.Provider>
  );
};
export default GlobalContext;
