function errorHandle (status, msg) {
  switch (status) {
    case 401:
      console.log('Unauthorized')
      break
    case 403:
      console.log('no auth')
      break

    // Not Found
    case 404:
      console.log('no res')
      break

    // Unprocessable Entity. Invalid input.
    case 422:
      console.log('no data')
      break

    // Internal Server Error
    case 500:
      console.log(msg)
      break

    default:
      console.log(msg)
  }
}

export default errorHandle
