import { HashRouter, Routes, Route } from "react-router-dom";
import NavBar from "components/NavBar/NavBar";
import ShopFooter from "components/Footer/ShopFooter";
import ErrorView from "views/ErrorView";
import OrderInfo from "views/OrderInfo";
import OrderConfirm from "views/OrderConfirm";

import "react-loading-skeleton/dist/skeleton.css";
import { confirmMsgs } from 'helpers/const'

import "assets/css/main.css";
function App() {
  return (
    <div>
      <HashRouter>
        <header>
          <NavBar></NavBar>
        </header>
        <Routes>
          <Route path="/order/:id" element={<OrderInfo />} />
          <Route path="/orderConfirm" element={<OrderConfirm {...{type: confirmMsgs.confirm.value}} />} />
          <Route path="/orderCancel" element={<OrderConfirm {...{type: confirmMsgs.cancel.value }} />} />
          <Route path="/orderFailed" element={<OrderConfirm {...{ type: confirmMsgs.failed.value }} />} />
          <Route path="/orderNotFound" element={<OrderConfirm {...{type: confirmMsgs.notFound.value }} />} />
          <Route path="*" element={<ErrorView />} />
        </Routes>
        <footer>
          <ShopFooter></ShopFooter>
        </footer>
      </HashRouter>
    </div>
  );
}

export default App;
